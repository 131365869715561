/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const TopicHero = ({ value }) => {
  const breakpoints = useBreakpoint();
  return (
    <div>
      <Container>
        {breakpoints.md ? (
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: ["column", "column", "row"],
              padding: "40px 0px 40px 0px",
            }}
          >
            <div
              sx={{
                width: ["100%", "100%", "calc(100% - 570px)"],
                h1: {
                  fontSize: ["2.75rem", "2.75rem", "4rem"],
                  margin: "0px 0px 10px 0px",
                  lineHeight: ["3rem", "4.125rem"],
                },
              }}
            >
              <h1
                sx={{
                  fontSize: ["2.75rem", "2.75rem", "4rem"],
                  margin: "0px 0px 10px 0px",
                  lineHeight: "1.2",
                }}
              >
                {value.name}
              </h1>
              <div
                className="hero-image-container"
                sx={{
                  ".gatsby-image-wrapper": {
                    margin: "50px 0px 30px 0px",
                    width: "100%",
                    overflow: "visible",
                    "> div": {
                      width: "100%",
                      maxWidth: "100% !important",
                    },
                  },
                  img: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                {value.image && (
                  <GatsbyImage
                    sx={{
                      img: {
                        width: "100%",
                        height: "auto",
                      },
                    }}
                    image={value.image.asset.gatsbyImageData}
                  />
                )}
              </div>
              <div
                sx={{
                  color: "textGray",
                  fontSize: "body",
                  lineHeight: "2.2rem",
                  ".cta": {
                    lineHeight: "1.5",
                  },
                }}
              >
                <PortableText
                  value={value._rawDescription}
                  components={PortableTextComponent}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: ["column", "row"],
              padding: "67px 0px 47px 0px",
            }}
          >
            <div
              sx={{
                width: ["100%", "100%", "600px"],
                marginRight: ["0px", "0px", "50px"],
              }}
            >
              <h1
                sx={{
                  fontSize: ["2.75rem", "3.5rem"],
                  margin: "0px 0px 10px 0px",
                  lineHeight: "3.4rem",
                }}
              >
                {value.name}
              </h1>
              <div
                sx={{
                  color: "textGray",
                  fontSize: "body",
                  lineHeight: "2.2rem",
                  ".cta": {
                    lineHeight: "1.5",
                  },
                }}
                className="hero-copy-container"
              >
                <PortableText
                  value={value._rawDescription}
                  components={PortableTextComponent}
                />
              </div>
            </div>

            <div
              className="hero-image-container"
              sx={{
                width: ["100%", "100%", "480px"],
                position: "relative",
                display: "flex",
                alignItems: "center",
                ".gatsby-image-wrapper": {
                  width: "100%",
                  overflow: "visible",
                  "> div": {
                    width: "100%",
                    maxWidth: "100% !important",
                  },
                },
                img: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              {value.image && (
                <GatsbyImage
                  sx={{
                    position: "absolute",
                  }}
                  image={value.image.asset.gatsbyImageData}
                />
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default TopicHero;
