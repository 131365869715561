/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/layout";
import ArticleTeaser from "../components/entities/article/articleTeaser";
import { Link } from "gatsby";
import Container from "../components/container";
import { graphql } from "gatsby";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import TopicHero from "../components/serializers/topicHero";

const ArticleByTopicIndex = (props) => {
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  return (
    <Layout>
      <SEO
        title={props.data.topic.name}
        lang="en"
        description={props.data.topic._rawDescription}
      ></SEO>
      <Container className="article-index-container">
        <div
          sx={{
            padding: "60px 0px 0px 0px",
          }}
        >
          <div
            sx={{
              display: "flex",
              fontSize: "1rem",
              textTransform: "uppercase",
            }}
            className="article-index-breadcrub"
          >
            <Link
              sx={{
                textDecorationThickness: "1px",
              }}
              to="/topics"
            >
              ALL TOPICS
            </Link>
            <div
              sx={{
                margin: "0px 5px",
              }}
            >
              {">"}
            </div>
            <div>
              {props.data.topic._rawCategory &&
                props.data.topic._rawCategory.name &&
                props.data.topic._rawCategory.name +
                  ": " +
                  props.data.topic.name}
            </div>
          </div>
          <TopicHero value={props.data.topic} />
          <ul
            sx={{
              padding: "80px 0px",
            }}
          >
            {props.data.article.nodes.map((article, index) => (
              <li
                key={index}
                sx={{
                  marginBottom: "90px",
                  listStyle: "none",
                  "&:last-child": {
                    marginBottom: "0px",
                  },
                }}
              >
                <ArticleTeaser article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div
          className="pager"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "90px",
          }}
        >
          <div
            sx={{
              marginRight: ["40px", "130px"],
            }}
            className="pager-previous-container"
          >
            {!isFirst && (
              <Link
                sx={{
                  fontSize: "1.5rem",
                  textDecorationThickness: "1px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                to={`/${props.pageContext.topic}/` + prevPage}
                rel="prev"
                aria-label={`Previous page of Articles in ${props.data.topic.name}`}
              >
                <span
                  sx={{
                    display: ["none", "block"],
                  }}
                >
                  Previous
                </span>
                <span
                  sx={{
                    display: ["flex", "none"],
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    sx={{
                      width: "15px",
                    }}
                    src={props.data.blueArrowLeft.publicURL}
                  />
                </span>
              </Link>
            )}
          </div>

          <ul
            className="pager-container"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              padding: "0px",
              margin: "0px",
            }}
          >
            {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
                sx={{
                  listStyle: "none",
                }}
              >
                <Link
                  className={`pagination-number-${i + 1}`}
                  to={`/${props.pageContext.topic}${
                    i === 0 ? "" : "/" + (i + 1)
                  }`}
                  activeClassName="pager-active"
                  aria-label={`Page ${i + 1} of Articles in ${
                    props.data.topic.name
                  }`}
                  sx={{
                    display: [
                      i + 1 == props.pageContext.currentPage ||
                      i + 1 == props.pageContext.currentPage + 1 ||
                      i + 1 == props.pageContext.currentPage + 2
                        ? "flex"
                        : "none",
                      i + 1 == props.pageContext.currentPage - 2 ||
                      i + 1 == props.pageContext.currentPage - 1 ||
                      i + 1 == props.pageContext.currentPage ||
                      i + 1 == props.pageContext.currentPage + 1 ||
                      i + 1 == props.pageContext.currentPage + 2
                        ? "flex"
                        : "none",
                    ],
                    width: "44px",
                    height: "44px",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none",
                    fontSize: "1.5rem",
                    margin: "0px 5px",
                    "&.pager-active": {
                      borderRadius: "50%",
                      backgroundColor: "primary",
                      color: "white",
                    },
                  }}
                >
                  {i + 1}
                </Link>
              </li>
            ))}
          </ul>
          <div
            sx={{
              marginLeft: ["40px", "130px"],
            }}
            className="pager-next-container"
          >
            {!isLast && (
              <Link
                sx={{
                  fontSize: "1.5rem",
                  textDecorationThickness: "1px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                to={`/${props.pageContext.topic}/` + nextPage}
                rel="next"
                aria-label={`Next page of Articles in ${props.data.topic.name}`}
              >
                <span
                  sx={{
                    display: ["none", "block"],
                  }}
                >
                  Next
                </span>
                <span
                  sx={{
                    display: ["flex", "none"],
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    sx={{
                      width: "15px",
                    }}
                    src={props.data.blueArrowRight.publicURL}
                  />
                </span>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default ArticleByTopicIndex;

export const articleIndexQuery = graphql`
  query articleByTopicListQuery($skip: Int!, $limit: Int!, $topic: String!) {
    topic: sanityTopic(slug: { current: { eq: $topic } }) {
      _rawDescription(resolveReferences: { maxDepth: 10 })
      _rawCategory(resolveReferences: { maxDepth: 10 })
      name
      image {
        asset {
          gatsbyImageData(width: 1100, placeholder: NONE)
        }
      }
    }
    blueArrowRight: file(name: { eq: "Right-Blue-Caret" }) {
      publicURL
    }
    blueArrowLeft: file(name: { eq: "Left-Blue-Caret" }) {
      publicURL
    }
    article: allSanityArticle(
      filter: {
        post_type: { eq: "article" }
        _id: { regex: "/^(?!draft).*$/" }
        topics: { elemMatch: { slug: { current: { eq: $topic } } } }
        slug: { current: { ne: null } }
      }
      limit: $limit
      skip: $skip
      sort: { fields: [pinned, publish_date], order: [DESC, DESC] }
    ) {
      nodes {
        headline
        post_type
        short_title
        pinned
        _rawIntroText(resolveReferences: { maxDepth: 10 })
        _rawBody(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
        main_image {
          asset {
            gatsbyImageData(width: 1440)
          }
        }
      }
    }
  }
`;
